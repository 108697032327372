<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2" v-if="!emailSent">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <price-x-logo/>
        </b-link>

        <b-card-title class="mb-1">
          {{ $t('title.forgot_password') }}
        </b-card-title>
        <b-card-text class="mb-2">
          {{ $t('messages.enter_your_email_and_well_send_you_instructions') }}
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
                :label="$t('label.email')"
                label-for="forgot-password-email"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
              >
                <b-form-input
                    id="forgot-password-email"
                    autofocus
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-overlay :show="isLoading" rounded spinner-small
                       spinner-variant="primary" class="d-inline-block">
              <b-button
                  variant="primary"
                  block
                  type="submit"
              >
                {{ $t('buttons.send_reset_link') }}
              </b-button>
            </b-overlay>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth.login'}">
            <feather-icon icon="ChevronLeftIcon"/>
            {{ $t('buttons.back_to_login') }}
          </b-link>
        </b-card-text>

      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
    <div class="auth-inner py-2" v-if="emailSent">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <price-x-logo/>
        </b-link>
        <p>{{ $t('messages.password_reset_link_sent') }}</p>
        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth.login'}">
            <feather-icon icon="ChevronLeftIcon"/>
            {{ $t('buttons.back_to_login') }}
          </b-link>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import PriceXLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BLink, BCardText, BCardTitle, BFormGroup, BFormInput, BForm, BButton,
  BOverlay
} from 'bootstrap-vue'
import {required, email} from '@validations'

export default {
  components: {
    PriceXLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BOverlay,
    ValidationProvider,
    ValidationObserver,
  },
  metaInfo() {
    return {
      title: this.$t('page_titles.forgotten_password')
    }
  },
  data() {
    return {
      userEmail: '',
      emailSent: false,
      isLoading: false,
      // validation
      required,
      email,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.$http.post('/user/send-reset-password', {
            email: this.userEmail
          }).then(() => {
            this.emailSent = true
          }).catch(error => {
            if(error.response.status === 422){
              this.$refs.simpleRules.setErrors({Email: error.response.data.errors.email})
            }else{
              this.$refs.simpleRules.setErrors({Email: error.response.data})
            }
          }).finally(() => {
            this.isLoading = false
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
